;(function ($) {
  'use strict';

  /**
   * When the document is ready
   */
  $(document).ready(function () {

    /**
     * @New Tab Links
     * Open `.onclick-newtab` in new tab
     */
    $('.onclick-newtab').click(function() {
      $(this).attr('target', '_blank');
      window.open($(this).attr('href'));
      return false;
    });

    /**
     * @Masonry
     */
    $('.masonry').masonry({
      itemSelector: '.item'
    });

    /**
     * @Toggle
     */
    $('.toggle').each(function() {
      var
        $this = $(this),
        $trigger = $this.find('.trigger'),
        $masonry = $this.parents('.masonry');

      // Bind our trigger to a click event
      $trigger.click(function() {
        $this.toggleClass('active');
        // If element is in masonry, trigger the layout
        if ($masonry.length !== 0) {
          $masonry.masonry('layout');
        }
        return false;
      });
    });

    /**
     * @Off Canvas
     */
    $('.oc-trigger').each(function () {
      var
        $this = $(this),
        $wrap = $this.closest('.oc-wrap'),
        $aside = $wrap.find('.oc-aside'),
        target = $this.attr('data-target'),
        reset = 'oc-wrap',
        is_active = false,
        close = function() {
          // Remove active class
          $wrap.removeClass('oc-active');
          // Remove delay class after the set transition duration
          setTimeout( function() {
            $wrap.removeClass('oc-delay');
          }, 500 );
        }
      ;

      // Button click event
      $this.click(function(e) {
        is_active = $wrap.hasClass('oc-active');

        // Check if it's a close button or if off-canvas is already active
        if(!target || is_active) {
          // Close off-canvas content
          close();
        } else {
          // Reset container class
          $wrap.attr('class', reset);
        }
        // Add target class
        if(target && !is_active) {
          $wrap.addClass(target);
          // Add active and delay classes after a slight delay
          setTimeout( function() {
            $wrap.addClass('oc-active oc-delay');
          }, 25 );
        }

        // Stop the default behavior
        return false;
      });

      // Aside click event
      $aside.click(function(e) {
        // Stop the click propogation from bubbling down to the container
        e.stopPropagation();
      });

      // Container click event
      $wrap.click(function(e) {
        // Close off-canvas content
        close();
      });

    });

    /**
     * @Off-Canvas Nav Accordion
     */
    $('.oc-nav').each(function() {

      // Get the children
      var $children = $(this).find('.children');

      // Put an element for displaying children before the element
      $children.each(function() {
        $(this).before('<span class="children-toggle"></span>');
      });

      var $toggle = $(this).find('.children-toggle');

      // Get our new children toggle element
      $toggle.click(function() {
        $(this).next('.children').toggle();
      });

    });


  });

  /**
   * When the images are loaded
   */
  $(window).load(function () {

    /**
     * @Masonry
     */
    $('.masonry').masonry('layout');

    /**
     * @Flexslider
     */
    $('.flexslider').flexslider({
      controlNav: false,
      directionNav: false
    });

  });

}(jQuery));
